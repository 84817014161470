import Types from '../common/collections/types';
import PrimitiveFormatters from '../common/collections/primitiveFormatters';
import Fields from '../common/collections/fields';
import * as blockUtils from '../common/components/blockUtils';
import * as userObservers from './components/userObservers';
import * as classes from '../common/components/classes';
import * as csvExport from '../export/csv/utils';
import Entity from '../common/models/entity.js';
import formatersAndStyles from '../common/components/formattersAndStyles.js';
import websocketClient from '../websocket/client'
import linkManager from '../blocks/components/linkManager';
import { initialize } from '../common/general';
import entityPresenter from './views/entityPresenter';
import PropertyKey from '../common/enums/propertyKey';
import cjTabs from '../common/components/cjTabs';
import InstanceViewer from '../common/components/instanceViewer';
import utils from '../common/components/utils';


app.linkManager = linkManager;
app.webSocketClient = websocketClient;

app.blockUtils = blockUtils;
app.userObservers = userObservers;
app.formattersAndStyles = formatersAndStyles;
app.classes = classes;
app.csvExport = csvExport;
app.entityPresenter = entityPresenter;
app.cjTabs = cjTabs;

app.types = new Types(app.raw.types);
app.fields = new Fields(app.raw.fields);
app.primitiveFormatters = new PrimitiveFormatters(app.raw.formatters);
app.models = new Backbone.Collection();
app.userStringResources = new Map()
app.raw.stringResources.forEach(resource => {
  app.userStringResources.set(resource.id, resource)
})


function initHeader() {
	const showHeader = _.find(app.properties, (p) => p.key === PropertyKey.SHOW_HEADER);
	if (showHeader && showHeader.value.value == 'false') {
		app.withoutHeader = true;
		$('#header').hide();
		$('#footer').hide();
		$('#sidebar-wrapper .sidebar-header').css("margin-top", '0');
		$('body').removeClass('sidebar-mini').addClass('sidebar-collapse');
		$('#wrapper').css('transition', 'none')
		$.AdminLTE.layout.fix();
	}
}

$(() => {
	initHeader();
	cjTabs.createTab({
		url: window.location.href,
		presentationInfo: {
			url: window.location.href,
			afterFormLoaded: () => {
				initialize();
				return websocketClient.connect()
						.catch(() => console.error('Could not open a websocket connection'));
			},
			hideLoading: app.hideLoading,
		}
	});
});
