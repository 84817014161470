/* @flow */
import Parser from 'papaparse';
import Formatter from '../../common/components/formatter';
import utils from '../../common/components/utils';
import MultilingualString from '../../common/models/multilingualString';

export { exportToCSV, prepareData };

function exportToCSV(fields: Array<*>, data: Array<*>): void {
	let csv = Parser.unparse({
		fields: fields,
		data: data
	});
	let blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
	let fileName = `export_entity${app.typeId}.csv`
	utils.downloadBlob(blob, fileName)
}

async function prepareData(items: Array<*>, columns: Array<*>) {
	let data = [];
	for(let i = 0; i < items.length; i++) {
		data.push(await fillItem(items[i], columns));
	}
	return data;
}

async function fillItem(item: Object, columns: Array<*>) {
	const result = [];
	for(let i = 0; i < columns.length; i++) {
		result.push(await getValue(item.get(columns[i].field), columns[i]));
	}
	return result;
}

async function getValue(data: any, column: Object) {
	const field = app.fields.get(column.fieldId);
	const fieldType = field.type();
	if (!field.isDynamic() && fieldType.isPrimitive()) {
		return getPrimitiveValue(data, column);
	} else {
		let viewId = $(column).attr('data-view-id') || null;
		if (!data) {
			return '';
		}
		let result = app.entityManager.fetchStringView(viewId, data.id);
		app.entityManager.fetchStringViews();
		await result;
		return result;
	}
}

function getPrimitiveValue(data: any, column: Object) {
	const fieldType = app.fields.get(column.fieldId).type();
	if (fieldType.primitive() === 'STRING') {
		return MultilingualString.getCurrentValue(data);
	} else if (fieldType.primitive() === 'BOOLEAN') {
		return data;
	} else {
		return Formatter.format(data, {
			type: fieldType
		});
	}
}
